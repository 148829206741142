$screen-widths: (
  phone: 576px,
  tablet: 768px,
  tablet_l: 1025px,
  desktop: 1300px,
  grid_max: 1460px,
  desktop_l: 1536px,
  desktop_xl: 1660px,
  site_max: 1921px
);

@mixin size-over($screen-width) {
  @if map-has-key($screen-widths, $screen-width) {
    $screen-width-value: map-get($screen-widths, $screen-width);

    @media (min-width: $screen-width-value) {
      @content;
    }

  }
}


@mixin size-under($screen-width) {
  @if map-has-key($screen-widths, $screen-width) {
    $screen-width-value: map-get($screen-widths, $screen-width);

    @media (max-width: ($screen-width-value - 1)) {
      @content;
    }

  }
}
