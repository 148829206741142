@import "@/styles/main.scss";
.text {
    position: relative;
    z-index: 2;
    color: #ffffff;
    max-width: 100%;
    width: 100%;
    &.padding-small {
        padding-right: 50px;
        @include size-under(tablet) {
            padding-right: 0px;
        }
    }
    &.padding-medium {
        padding-right: 100px;
        @include size-under(desktop_xl) {
            padding-right: 50px;
        }
        @include size-under(tablet) {
            padding-right: 0px;
        }
    }
    &.padding-large {
        padding-right: 150px;
        @include size-under(tablet) {
            padding-right: 0px;
        }
    }
    &.size-small {
        max-width: 25%;
        @include size-under(tablet) {
            max-width: 100%;
        }
    }
    &.size-medium {
        max-width: 50%;
        @include size-under(desktop_xl) {
            max-width: 75%;
        }
        @include size-under(tablet) {
            max-width: 100%;
        }
    }
    &.size-large {
        max-width: 75%;
        @include size-under(tablet) {
            max-width: 100%;
        }
    }
    &.size-full {
        max-width: 100%;
    }

    .h1 {
        @include h1;
    }

    .h2 {
        @include h2;
    }

    .h3 {
        @include h3;
    }

    .h4 {
        @include h4;
    }

    .h5 {
        @include h5;
        line-height: 1.1;
        margin-bottom: 30px;
        font-weight: 300;
    }

    h2 {
        width: 100%;
        line-height: 0.9;
        margin-bottom: 100px;
        @include size-under(site_max) {
            margin-bottom: 60px;
        }
        @include size-under(desktop) {
            margin-bottom: 60px;
        }
        @include size-under(tablet) {
            margin-bottom: 30px;
        }
    }


    h5 {
        line-height: 1;
        font-weight: 300;
        margin-bottom: 60px; 
        @include size-under(desktop) {
            margin-bottom: 20px;
        }
    }

    p {
        font-weight: 300;
        margin-bottom: 30px;
    }
    a {
        color: var(--aqua);
        transition: 0.3s all ease-in-out;
        &:hover {
            color: var(--blue);
        }
    }

}

.column-1 {
    margin-left: 0%;
    @include size-under(tablet_l) {
        margin-left: 0%;
    }
}

.column-2 {
    margin-left: 25%;
    @include size-under(tablet_l) {
        margin-left: 0%;
    }
}

.column-3 {
    margin-left: 50%;
    @include size-under(tablet_l) {
        margin-left: 0%;
    }
}

.column-4 {
    margin-left: 75%;
    @include size-under(tablet_l) {
        margin-left: 0%;
    }
}

.column-5 {
    margin-left: 100%;   
    @include size-under(tablet_l) {
        margin-left: 0%;
    }
}