@import "@/styles/main.scss";
.hero {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    padding: 250px var(--column-small);
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include size-under(desktop) {
        padding: 150px var(--column-small);
    }
    @include size-under(tablet) {
        padding: 150px var(--column-small) 50px var(--column-small);
    }
    &.text-small {
        padding-bottom: 75px;
        h1 {
            font-size: clamp(4rem, 9vw, 9rem);
            max-width: 1800px;
            letter-spacing: -6px;
            line-height: .9;
            @include size-under(tablet) {
                letter-spacing: -4px;
                font-size: clamp(3rem, 9vw, 4rem);
            }
        }
    }
    .hero-text {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 70px;
        h5 {
            font-weight: 400;
            max-width: calc(50%);
            width: 100%;
            line-height: 1;
            letter-spacing: -2px;
            padding-right: 20px;
            margin-top: 100px;
            @include size-under(desktop) {
                max-width: calc(75%);
            }
            @include size-under(tablet) {
                max-width: 100%;
                margin-top: 30px;
                padding-right: 0px;
            }
        }
        p {
            max-width: calc(var(--column-large)*2);
            margin-left: 5%;
        }
    }
}