@import "@/styles/main.scss";
.content-area {
    position: relative;
    padding: 180px 0px 125px 0px;
    padding-left: var(--column-small);
    padding-right: var(--column-small);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &.is-hero {
        padding-top: 0px;
    }
    @include size-under(site_max) {
        padding: 150px var(--column-small) 100px var(--column-small);
    }
    @include size-under(desktop_l) {
        padding: 140px var(--column-small) 100px var(--column-small);
    }
    @include size-under(tablet) {
        padding: 120px var(--column-small) 75px var(--column-small);
    }
    @include size-under(phone) {
        padding: 80px var(--column-small) 50px var(--column-small);
    }
    
    img {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 15% 0px 0px 15%;
        mix-blend-mode: hard-light;
        opacity: 0.4;
        width: calc(var(--column-large) + var(--column-small));
        @include size-under(tablet) {
            opacity: 0.15;
            width: calc(var(--column-large) * 2 + var(--column-small));
        }
    }
    &:last-of-type {
        padding-bottom: 250px;
    }
}
