@import "@/styles/main.scss";
.button {
    position: relative;
    text-transform: uppercase;
    color: #fff;
    font-size: 28px;
    line-height: 1;
    margin-top: 50px;
    padding: 10px 0px 10px 0px;
    font-weight: 700;
    letter-spacing: -0.56px;
    font-family: var(--font-display);
    display: inline-block;
    z-index: 1;
    transition: 0.3s all ease-in-out;
    &.service-button {
        @include size-under(desktop) {
            margin-bottom: 60px;
        }
        @include size-under(phone) {
            margin-bottom: 30px;
            margin-top: 20px;
        }
    }
    &:hover {
        svg {
            transform: translate(50%, -50%) scale(1.1);
            right: -20px;
        }
    }
    &.align-right {
        @include size-under(tablet) {
            margin-left: auto !important;
            margin-right: var(--column-small) !important;
        }
    }
    &.secondary {
        color: #191919;
        svg {
            color: #191919;
        }
    }
   
    a {
        position: relative;
        z-index: 2;
    }

    svg {
        transition: 0.3s all ease-in-out;
        position: absolute;
        right: 0px;
        color: #ffffff;
        top: 47%;
        bottom: 0;
        transform: translate(50%, -50%);
        height: 60px;
        width: 60px;
    }
    @include size-under(tablet) {
        font-size: 23px;
    }
}

.column-1 {
    margin-left: 0%;
}

.column-2 {
    margin-left: 25%;
    @include size-under(tablet_l) {
        margin-left: 0%;
    }
}

.column-3 {
    margin-left: 50%;
    @include size-under(tablet_l) {
        margin-left: 0%;
    }
}

.column-4 {
    margin-left: 75%;
    @include size-under(tablet_l) {
        margin-left: 0%;
    }
}

.column-5 {
    margin-left: 100%;
    @include size-under(tablet_l) {
        margin-left: 0%;
    }
}
