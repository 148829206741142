@mixin h1 {
	font-size: 19.18rem;
	line-height: 0.7;
	letter-spacing: -14.94px;
	font-family: var(--font-display);
	font-weight: 600;
	@include size-under(site_max) {
		font-size: 14rem;
	}
	@include size-under(desktop) {
		font-size: 14rem;
	}
	@include size-under(tablet_l) {
		font-size: 8rem;
		letter-spacing: -7.8px;
	}
	@include size-under(tablet) {
		font-size: 6rem;
		letter-spacing: -5.8px;
	}
	@include size-under(phone) {
		font-size: 5.5rem;
		letter-spacing: -3.8px;
	}
}

@mixin h2 {
	font-size: 7.3rem;
	line-height: 1.5;
	font-weight: 600;
	letter-spacing: -8.04px;
	@include size-under(site_max) {
		font-size: 7rem;
	}

	@include size-under(tablet_l) {
		font-size: 5rem;
		letter-spacing: -5.0px;
		margin-bottom: 80px;
	}
	@include size-under(tablet) {
		font-size: 3rem;
		letter-spacing: -2.8px;
		margin-bottom: 40px;
	}
}

@mixin h3 {
	font-size: 5.625rem;
	line-height: 1.5;
	font-weight: 500;
	@include size-under(desktop) {
		font-size: 3.75rem;
		line-height: 1;
		letter-spacing: -4.0px;
		margin-bottom: 40px;
	}
}

@mixin h4 {
	font-size: 4.375rem;
	line-height: 1.5;
    @include size-under(tablet_l) {
        font-size: 2.2rem;
        line-height: 1;
    }
}

@mixin h5 {
	font-size: 3.125rem;
	line-height: 1.5;
	@include size-under(site_max) {
		font-size: 2.65rem;
	}
	@include size-under(tablet_l) {
		font-size: 2.6rem;
		letter-spacing: -2px;
	}
	@include size-under(tablet) {
		font-size: 2rem;
		letter-spacing: -1.5px;
	}
	@include size-under(phone) {
		max-width: 100%;
	}
}

@mixin h6 {
	font-size: 1.8rem;
	line-height: 1.5;
}